.Edit {
  width: 750px;
  height: 100%;
  min-height: 1050px;
  // overflow-y: auto;
  background: white;
  box-sizing: border-box;
  // box-shadow: 0px 0px 10px 10px #FAFBFD;

  // margin: 0 20px;
  .footer {
    background: white;
  }

  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  .edit-mark {
    width: 750px;
    // border: 1px solid #dedede;
    border-radius: 5px;
    // padding: 15px 20px;
    box-sizing: border-box;
    height: auto;
    overflow: hidden;
  }

  .edit-mark3 {
    width: 300px;
    height: auto;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .edit-header {
    width: 750px;
    height: auto;
    box-sizing: border-box;
    padding: 10px 20px;
    line-height: 40px;
    color: #8297b3;
    font-size: 20px;
    display: flex;
    justify-content: space-between;

    .smallfont {
      border-left: 1px solid #e6eef2;
      padding-left: 15px;
      margin-left: 10px;

      text-transform: uppercase;
      font-size: 10px;
      margin-right: 15px;
      letter-spacing: 1px;
      font-weight: 700;
    }

    .smallfont-right {
      border-right: 1px solid #e6eef2;
      padding-right: 15px;
      margin-right: 10px;

      text-transform: uppercase;
      font-size: 10px;
      margin-left: 15px;
      letter-spacing: 1px;
      font-weight: 700;
    }
  }

  .edit-center {
    // overflow: hidden;
    width: 750px;
    height: auto;
    padding: 20px 15px;
    border-radius: 5px;
    // box-sizing: border-box;
    overflow: hidden;
    border: 1px solid #dedede;
    margin-bottom: 40px;

    .edit-img {
      width: 100%;
      float: left;
      margin-right: 20px;

      .edit-img-banner {
        width: 35%;
        border: 1px solid #dedede;
        padding: 10px;
        border-radius: 3px;
      }
    }

    .edit-table {
      // float: right;
      width: 350px;
    }

    .edit-mark2 {
      float: right;

      .edit-news {
        font-size: 16px;
        color: #6d6d6d;
        line-height: 24px;

      }
    }

    .Personal-Data {
      width: 240px;
      float: left;
      margin-right: 10px;

      li {
        font-size: 16px;
        color: #6d6d6d;
        line-height: 24px;
        border-right: 1px solid #dedede;
      }

      img {
        width: 15px;
        height: 15px;
        margin-bottom: 3px;
      }
    }

    .Personal-Datatwo {
      width: 170px;
padding-left: 20px;
      li {

        border-right: none;

        img {
          width: 15px;
          height: 15px;
          margin-bottom: 3px;
        }
      }
      h5{
        font-style: italic;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 3px;
      }
      .top_ranking{
        text-align: center;
        font-size: 44px;
        font-weight: 500;
        color: #DAA26A;
        margin-top: 10px;
      }
    }
.Personal-Data-background{
      padding: 20px 10px;
    width: 170px;
    background: #f4f4f4;
    margin-top: 20px;
    border-radius: 10px;
    text-align: center;
    .top_ranking {
      color: green;
      font-size: 28px;
      font-weight: 500;
      margin-top: 10px;
      text-align: center;
  }

}

    .li1 {
      margin-bottom: 10px;
      font-size: 26px;
      font-weight: 600;
      color: #0063B5;
      // color:
    }
  }

  .table-h4 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;

    .playtotal {
      display: inline-block;
      float: right;
      width: 80px;
      padding: 1px 5px;
      border-radius: 3px;
      text-align: center;
      background: #F7F7F7;
      border: 1px solid rgba(196, 183, 183, 0.9);
      color: #577CFA;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 700;
  }

  .edit-table {
    // border: 1px solid black;
    width: auto;
    height: auto;

    tr {
      border: 1px solid #dedede;
    }

    thead {
      background: #F8F8F8;
    }

    th {
      height: 30px;
      line-height: 30px;
      color: #6d6d6d;
    }

    td {
      text-align: center;
      height: 60px;

      img {
        width: 30px;
      }
    }

    .td2 {
      text-align: left;
      font-size: 12px;

      span {}
    }

    .td2-div {

      height: 15px;
      background: #008000;
      margin-bottom: 10px;
    }

    .th1 {
      width: 100px;
    }

    .th2 {
      width: 150px;
    }

    .th3 {
      width: 80px;
    }
  }

  .edit-table2 {
    // border: 1px solid black;
    width: 650px;
    height: auto;
    margin: 0 auto;

    tr {
      border: 1px solid #dedede;

    }

    thead {
      background: #F8F8F8;
    }

    th {
      height: 30px;
      line-height: 30px;
      color: #6d6d6d;
    }

    td {
      text-align: center;
      height: 60px;

      img {
        width: 30px;
      }
    }

    .td2 {
      text-align: center;
      font-size: 12px;

      span {}
    }

    .td2-div {

      height: 15px;
      background: #008000;
      margin-bottom: 10px;
    }

    .td2-img {
      width: 40px;
      height: 40px;
      border: none;
      border-radius: 50%;
    }

    .th1 {
      width: 100px;
    }

    .th2 {
      width: 150px;
    }

    .th3 {
      width: 80px;
    }
  }

  .edit-details {
    .details-center {
      a {
        line-height: 20px;
      }

      // margin: 20px;
      width: 750px;
      // min-width: 700px;
      height: auto;
      min-height: 500px;
      border: 1px solid #dedede;
      border-radius: 5px;
      padding: 15px 20px;
      box-sizing: border-box;

      h4 {
        font-size: 19px;
        margin: 10px 0;
      }

      h5 {
        font-size: 16px;
        color: #6d6d6d;
        line-height: 24px;
      }

      p {
        font-size: 16px;
        color: #6d6d6d;
        line-height: 24px;
        margin-top: 10px;
      }

      hr {
        margin: 20px 0;
      }

      ul {

        margin-top: 10px;
        padding-left: 20px;

      }

      li {
        list-style: disc;
        font-size: 16px;
        color: #6d6d6d;
        line-height: 24px;

      }

      .zmd {
        position: relative;
      }

      .img {
        /* height: 400px; */
        text-align: center;
        background-color: #fff;
      }

      img {
        height: 400px;
        margin: auto;
        display: inline !important;
      }

      .leftButton,
      .rightButton {
        border: none;
        height: 36px;
        width: 36px;
        transition: 1s;
        border-radius: 50%;
        background-color: white;
        color: #16B7CC;
        position: absolute;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        // opacity: 0.3;

        box-shadow: 0px 1px 10px 0px RGBA(0, 0, 0, 0.17);
        border: 0px solid RGBA(0, 0, 0, 1);
      }

      .leftButton:hover,
      .rightButton:hover {
        opacity: 1;
        background: #16B7CC;
        color: white;
      }


    }

  }
}

.className0 {
  color: green;
}

.className1 {
  color: yellowgreen;
}

.className2 {
  color: burlywood;
}

.table_one {
  width: 700px;
  // float: right;
}

.table_two {
  width: 48%;
  float: left;
}

.cheer {
  width: 700px;
  height: auto;
  float: left;
  margin-top: 20px;

  .cheer_list {

    //   display: flex;
    //   flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: center;
    li {
      width: 80px;
      float: left;
      height: 80px;
      border: 1px solid #dedede;
      border-radius: 50%;
      padding: 5px;
      box-sizing: border-box;
      // margin-bottom: 20px;
      margin: 10px 10px;
      cursor: pointer;

      .cheer_list_banner {
        width: 100%;

        height: auto;
        border-radius: 50%;
      }

      p {

        margin: 10px 0;
        padding: 0 5px;
        font-size: 18px;
        color: #DF8D37;
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: none;

        img {
          width: 12px;
          height: auto;
        }

        .credit_img {
          width: 17px;
          margin-left: 3px;
          margin-bottom: 3px;
        }
      }
    }
  }

  .cheer_list_current {
    border: 1px solid rgb(80, 139, 80);
    background: #c3e3c3;

    .cheer_list_current_p {

      width: 100%;
      text-align: center;
      color: #008000;
      display: block;
      display: none;
    }
  }
}

.editshow {
  display: none;
}
.bt{
  height: 20px;
  // line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}
.cltg-li-top{
  width: 90%;
  height: 40px;

  background: #F8B830;
  font-weight: 700;
  line-height: 38px!important;
  text-align: center;
  border-radius: 10px 10px 0 0;
  // margin-left: -10px;
  font-size: 26px!important;
}
.cltg-li-bottom{
  width: 90%;
  height: 40px;
line-height: 38px!important;
  border-radius:0 0 10px 10px ;
  border: 1px solid #dedede;
  border-right: 1px solid #dedede!important;
  border-top: none;
  margin-top: 0!important;
  font-size: 26px!important;
  // margin-left: -10px;
  box-sizing: border-box;
}
.clcs-top{
  display: flex;
}
.clcs-banner{
  width: 100px;
  height: 90px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-right: 30px;
  padding: 10px ;
}
.clcs-center{
  margin: 30px 0;
}
.clcs-center-li{
  width: 100%;
  height: 120px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  img{
    width: 90px;
    height: 90px;

  }
  .clcs-center-li-right{
    display: flex;
    flex-direction: column;
  justify-content: space-between;
  align-items: self-end;
  img{
    width: 40px;
    height: 40px;
    margin: 7px 0;
  }
  }
}
.big-box{
  width: 100%;
  height: 120px;
  overflow: hidden;
  cursor:pointer; //鼠标变为手的形状
user-select:none; //取消字体双击选中
position: relative;
margin-bottom: 20px;
}

.clcs-next{
  margin-top: 20px;
font-size: 14px;

width: 100%;
display: flex;
flex-direction: column;
.clcs-next-button{
  color: gray;
  width: 100px;
  height: 35px;
  padding-bottom: 1px;
  margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  cursor:pointer; //鼠标变为手的形状
user-select:none; //取消字体双击选中
}
.clcs-next-Spin{
  margin: 0 auto;
}
}
.ant-pagination{

}
.clcs-center-li-bottom{
  background: #e6f4ff;
position: absolute;
top: 120px;
left: 0;
.li-bottom-top{
  width: 40px;
  height: 40px;
}
.clcs-center-li-right2{
  display: flex;
  flex-direction: column;
justify-content: space-between;
align-items: self-end;
margin-right: 10px;
img{
  width: 30px;
  height: 30px;
  margin: 7px 0;
}
}
}
.big-box:hover{
  .clcs-center-li-bottom{
    top: 0;
  }
}