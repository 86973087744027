.select {
    width: 750px;
    height: 310px;

    .pageul {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    ul {

        width: 100%;
        padding: 0 ;
        height: 170px;
padding-top: 14px;
        word-wrap:break-word; 
        overflow:hidden;
        li {
            width: 72px;
            height: 72px;
            margin: 0 4px;
            cursor: pointer;
            list-style: none;
            border: 1px solid #dedede;
            border-radius: 5px;
            padding: 5px;
            float: left;

            img {
                width: 55px;
                height: 55px;
                border-radius: 5px;
                background: white;
                box-sizing: border-box;
            }

            margin-bottom: 10px;
        }
    }

    // .nobuyimg {
    //     width: 95%;
    //     height: 190px;
    //     overflow: hidden;
    //     margin: 0 25px;
    //     display: flex;
    //     box-sizing: border-box;

    //     // justify-content: space-around;

    //     .font-left {
    //         width: 14px;
    //         height: 75px;
    //         background: black;
    //     }
    // }

    .buyimg {
        width: 100%;
        overflow: hidden;
        // margin: 0 25px;
        box-sizing: border-box;
        display: flex;
        border: 1px solid #dedede;
        border-radius: 5px;
        height: 95px;
        padding: 0;
        img {
            width: 70px;
            height: 70px;
            border-radius: 5px;
            background: white;
            box-sizing: border-box;
        }
        .buyimg-message {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
                text-align: center;
                line-height: 50px;
                color: #8f8d8d;
            }
        }

        li {
            float: left;
            border: none;
            padding: 6px;
            margin-bottom: 0px;

        }

        .font-left {
            width: 14px;
            height: 75px;
            background: black;
        }
    }

    .buyimg{
        // margin: 0 20px;
        // max-width: 95%;
        padding: 0 15px;
        display: flex;
        position: relative;
        justify-content: left;
        border: 1px solid #dedede;
        border-radius: 5px;
        li{
            width: 95px;
            height: 95px;
            float: left;
            margin: 0 4px;
            border: none;
        }
        img {
            width: 85px;
            height: 83px;
            border-radius: 5px;
            background: white;
            box-sizing: border-box;
        }
    }
    .img-message {
        width: 100%;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            text-align: center;
            line-height: 90px;
            color: #8f8d8d;
        }
    }
    .pageul2{
        // display: flex;
        // justify-content: space-around;
        // align-items: center;
        .left1{
            line-height: 90px;
            position: absolute;
            left: 0;
            top: 0;
             
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .left2{
            line-height: 90px;
            position: absolute;
            right: 0;
            top: 0;
   
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .bt{
        height: 20px;
        // line-height: 20px;
        color: rgba(0, 0, 0, 0.45);
    }
}
.clcsselect{
    height: 170px;
}

