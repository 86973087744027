@bgcolor:white;

body {
    font-size: 14px;
    color: #333;
    font-family: "微软雅黑";
    background: @bgcolor;
}
#app{
    height: 100vh;    background: @bgcolor;
}
.ant-layout{
    margin-top: 0;
}
.ant-layout-content{
    // width: 710px!important;
   
    background: @bgcolor;
   
}
.ant-layout-sider-children {
    background: @bgcolor;
    margin-top: 0!important;
}
.html-center{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    overflow: hidden;
}
header{
    height: 70px;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: @bgcolor;
    .logo{
        height: 60px;
    }
    .right{
       .defaultImg{
        width: 40px;
        height: 40px;
        border-radius: 50%;
       }
       span{
        margin: 0 10px;
       }
       .ant-dropdown-trigger {
        height: 40px;
        display: block;
        color: black;
        &:hover{
            color: #1890ff;
        }
       }
    }
}
.aside{

   background: white;
    // margin-bottom: 30px;

}
.container_box{
  
    // padding: 20px;
    // height: calc(100vh - 125px);
    // overflow-y: auto;
    width: 750px;
    height: auto;
   margin: 0 20px;
    // overflow: hidden;
    // float: left;
}
footer{
    
    height: 70px;
    background: black;
    color: white;

}