.ant-modal-body{
    display: flex;
    justify-content: space-around;
    .ant-modal-close{
        display: none;
    }
}
.ant-modal-footer{
    display: flex;
    justify-content: space-around;
    .form-button{
        width: 300px;

    }
    .form-button-color{
        background: #027405;
        
    }
    .form-button-color:hover{
        background-color: rgb(98 171 100) !important;
    }
}
#basic1{
    width: 44%;
    padding: 0 20px;
    .tourist{
        padding: 10px 20px;
        padding-right: 40px;
        p{
           color: green;
            padding-left: 40px;
            margin: 20px 0;
            font-size: 22px;
        }
        .form-left-p{
            
            padding: 10px;
            padding-left: 20px;
            background: #FFE1A1;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            font-size: 20px;
        }
    }
    h3{
        margin-bottom: 20px;
    }
}
#basic2{
    width: 48%;
    padding: 0 40px;
    h3{
        margin-bottom: 20px;
    }
    .basic2-item-p{
        margin-bottom: 0;
    }
}
#basic3{

    // padding: 0 40px;
    h3{
        margin-bottom: 20px;
        color: #676262;
    }
    .basic2-item-p{
        margin-bottom: 0;
    }
    .ant-modal-close{
        display: block;
    }
}

.space{
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    div{
        // margin-top: 20px;
        height:47%;
        width: 1px;
        background: #d9d9d9;
    }
}


 