
.header{
    // background: #42A7DF;
    background: #F8F8F8;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    .logo{
       
        width: 450px;
        height: 40px;
        // float: left;

        box-sizing: border-box;
    
        font-size: 22px;
        font-weight: 700;
        display: flex;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            // background: #42A7DF;
            background: #F8F8F8;
            // color: white;
            color: #686868;
            img{
                width: 50px;
                height: 40px;
            }
            span{
                color: #F75E5C;
            }
        }
       
    }
    .search{
        // width: 600px;
        // height: 60px;
        // float: left;
        display: block;
       background: white;
        border-radius: 5px;
        // margin-left: 150px;
        
    }
    .user{
        width: 200px;
        line-height: 40px;
        text-align: right;
        color: #424242;
    }
    .icon{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid white;
        border: 1px solid #424242;
        font-size: 28px;
        color: white;
        color: #424242;
        text-align: center;
line-height: 40px;
margin-right: 20px;
margin-left: 20px;
float: right;
overflow: hidden;
    }

}
.ant-dropdown-menu-item{
    .ant-dropdown-menu-item-icon{
        margin-right: 20px!important;
    }
}
.userright{
    width: 450px;
    padding-left: 20PX;
    float: right;
    display: flex;
    justify-content: right;
    .credits{
        font-size:26px;
        color:green;
        // line-height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        img{
            width: 26px;
            height: 26px;
margin-left: 5px;
        }
    }
}
.Release{
    width: auto;
    height: 40px;
    border: 1px solid #dedede;
    // padding: 10px;
    border-radius: 3px;
    background: #fff;
    ul{
        // display: flex;
        padding: 0;
        li{
            list-style: none;
            // width: auto;
            height: 40px;
            // margin-right: 20px;
            // line-height: 40px;
            // color: blue;
            // font-size: 24px;
            padding: 10px;
        }
    }
}
.user_avatar{
    width: 38px;
    height: 38px;
    margin-bottom: 10px;
    // border: 1px solid #424242;
    // border-radius: 20px;
}
.parent {
    width: 750px;
    height: 40px;


    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    }
    .parent::-webkit-scrollbar {
    display: none;
    }
    /*设置的子盒子高度大于父盒子，产生溢出效果*/
    .child {
    height: auto;
    }
    
    .child li {
    height: 40px;
    // margin: 2px 0;
display: flex;
align-items: center;
    }
    .modelspan{
        display: inline-block;
        margin-left: 20px;
        color: blue;
        cursor:pointer;

    }
    .modelmessage{
        display: inline-block;

        width: auto;
        max-width: 80%;
        overflow: hidden;
        overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;

    }