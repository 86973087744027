.model_img{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    font-size: 20px;
    p{
        margin: 5px 0;
    }
    .model_img_right{
        width: 50%;
        img{
            width: 33px;
            margin-left: 3px;
            margin-bottom: 5px;
        }
    }
}