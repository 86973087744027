.footerInfomercial{
       margin-top: 50px;
   width: 100%;
   background: #E1E3E8;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   position: sticky;
   bottom: 0; /* 与底边界对齐 */
z-index: 100;
display: none;
    .footerInfomercial_img{
        width: auto;
        margin: 0 auto;
    }
}