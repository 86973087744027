.footer{
    width: 100%;
    height: 115px;
    // padding: 20px;
    background: #424242;
    // margin-top: 50px;
    overflow: hidden;
    p{
        margin: 30px 0;
        width: 100%;
        display: inline-block;
text-align: center;

    color: #96a0a9
    }
    ul{
  
        width: 100%;
        height: 30px;
        display: flex;
        margin-top: 30px;
        justify-content: center;
        align-items: center;
        display: none;
        li{
            width: 28px;
            height: 28px;
            margin: 4px;
list-style: none;
            img{
                width: 28px;
                height: 28px;
            }
        }
    }
    
}
