.Infomercial{
    width: 250px;
    // height: auto;
    // border: 1px solid #dedede;
    float: left;
    margin-top: 10px;

    img{
        width: 250px;
    }
    .img1{
        width: 200px;
        height: auto;
        margin: 22px 25px;
    }
    .img2{
      display: none;
    }
}