.asdie-all{
    width: 240px;
    // height: calc(100vh - 70px);
    height: auto;
    // margin-left: 100px;
    // overflow-y: scroll;
    overflow: hidden;
    background: white;

//    padding-bottom: 30px;
//    padding-left: 100px;
  
    .ant-input-search-button{
        border-left: none;
    }
    .divider{
        height: 7px;
        width: 16px;
        border-radius: 2px;
        background-color: #537eff;
        margin-bottom: 40px;
        margin-left: 10px;
margin-top: 40px;
    }
    .social{
        width: 140px;
        height: auto;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        img{
            width: 24px;
            height: auto;
        }
    }
    .ant-menu-item{
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
        border-radius: 0;
    }
    .ant-menu-submenu {
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
        border-radius: 0;
    }
    :where(.css-dev-only-do-not-override-26rdvq).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected{
        background: white;
    }
    :where(.css-dev-only-do-not-override-26rdvq).ant-menu-light.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-26rdvq).ant-menu-light.ant-menu-root.ant-menu-vertical{
        border: none;
    }
    .aside-menu{
        width: 220px;
        height: auto;
        // overflow-y: auto;
        padding: 0;
        margin: 0 10px;
        list-style: none;
        margin-bottom: 30px;
        li{
            
            height: auto;
         display: flex;
         justify-content: left;
         align-items: center;
         padding: 10px 20px;
  
         border-bottom: 1px solid rgba(5, 5, 5, 0.06);
         cursor:pointer; //鼠标变为手的形状
user-select:none; //取消字体双击选中
            img{
                width: 30px;
                height: 30px;
                margin: 0 5px;
            }
            span{
                width: 140px;
//                 white-space:nowrap;
// overflow:hidden;
// text-overflow:ellipsis;
            }
        }
        li:hover{
            background: #F0F0F0!important;
        }
    }
      
}
.asdie-all::-webkit-scrollbar {
    display: none;
}
.asdie-header{
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 30px 0;

    img{
        width: 40px;
        height: 40px;
margin-right: 10px;
    }
    span{
        font-weight: 700;
        font-size: 20px;
    }
}