.login{
    background: white;
    width: 100vw;
    height: 100vh;
    position: relative;
    .login_box{
        width: 400px;
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%,-50%);
        img{
            display: block;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}