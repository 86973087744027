.home-header{
    width: 100%;
    height: 500px;
    overflow: hidden;
    background:url(../../assets/img/banner/WX20231010-172600.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    .header{
        width: 100%;
        height: 80px;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        .logo2{
       
            width: auto;
            height: 40px;
            float: left;
    
            box-sizing: border-box;
        
            font-size: 22px;
            font-weight: 700;
            div{
                display: flex;
                justify-content: center;
                align-items: center;
             background: none(0, 0, 0, 0.6);
                color: white;
                img{
                    width: 50px;
                    height: 40px;
                }
                span{
                    color: #F75E5C;
                }
            }
           
        }
        .icon{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid white;
            font-size: 28px;
            color: white;
            text-align: center;
line-height: 40px;
        }
      
    }
    .header-js{
        width: 100%;
        height: 620px;
        background: rgba(0, 0, 0, 0.6);
        overflow: hidden;
        .ant-input-group-addon{
            background: white;
        }
        .p1{
            color: white;
            font-weight: 700;
            font-size: 40px;
            text-align: center;
            margin: 40px 0;
        }
        .p2{
            color: white;
            font-weight: 400;
            font-size: 18px;
            text-align: center;
        }
        .search{
            width: 600px;
            // height: 60px;
            display: block;
            padding: 15px;
            background-color: rgba(255, 255, 255, .2);
            border-radius: 5px;
            margin: 0 auto;
            margin-top: 40px;
        }
        .bottom{
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:50px auto;
            p{
                width: 30%;
                color: white;
                font-weight: 400;
                font-size: 15px;
                text-align: center;
            }
        }
        ul{
            width: 280px;
            margin: 0 auto;
            height: auto;
            padding: 0;
            display: flex;
            justify-content: space-around;
            list-style: none;
            color: white;
            li{
width: 120px;
height: 120px;
background: #41A6DF;
border-radius: 5px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
cursor:pointer; //鼠标变为手的形状
img{
    width: 35px;
    height: 35px;
    margin-bottom: 5px;
}
            }
li:hover{
    background: #363F48;
}

            
        }
        .header-footer{
            width: 100%;
            text-align: right;
            color: white;
            font-weight: 400;
            margin-top: 40px;
            font-size: 14px;
            padding: 0 30px;
            box-sizing: border-box;
        }
    }
}
.newgame{
    width: 100%;
    height: auto;
    text-align: center;
    padding-top: 30px;
    h3{
        margin: 20px auto;
width: 40px;
height: 40px;
border-radius: 20px;
color: #41A6DF;
border: 1px solid #41A6DF;
line-height: 40px;
    }
    h2{
font-weight: 700px;
font-size: 35px;
color: black;
    }
    h4{
        font-size: 16px;
        color: #797979;
        font-weight: 400;
     
    }
}
.Home-card{
    width:1240px;
    height: auto;
    padding: 10px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
}
.card{
   
    width: 380px;
    height: auto;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    cursor:pointer; //鼠标变为手的形状
    margin-top: 30px;
    img{
        width: 100%;
        height: auto;
    }
    .yy{
        background: rgba(0, 0, 0, 0) linear-gradient(0deg, rgba(0, 0, 0, .9) 8%, rgba(0, 0, 0, 0) 94%) repeat scroll 0 0;
        position: relative;
    bottom: 20px;
    padding: 15px 14px 5px 14px;

    }
    .p1{
        font-size: 24px;
        color: #666;
        padding: 0 20px;
    }
    .p2{
        padding: 0 20px;
        font-size: 16px;
    color: #6d6d6d;
    line-height: 24px;
    img{
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }
    .anticon-tags{
        margin-right: 10px;
    }
    }
}
.card2{
    border: none;
}
.fg{
    width: 100%;
    height: 40px;
}
.comment{
    width: 100%;
    height: auto;
    justify-content: center;
}
.video{
    width: 100%;
}
.comment{
    h3{
        font-weight: 700px;
        font-size: 35px;
        color: black;
        margin-bottom: 40px;
        padding-left: 20px;
    }
  p{
    color: #6d6d6d;
    line-height: 24px;
    font-weight: 400px;
    font-size: 15px;
    padding: 20px 40px;
  }
  span{
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    img{
        margin-right: 10px;
    }
  }
}
.footer{
    width: 100%;
    height: auto;
    // padding: 20px;
    background: #424242;
    margin-top: 50px;
    p{
        width: 100%;
        display: inline-block;
text-align: center;

    color: #96a0a9
    }
    ul{
        width: 100%;
        height: 30px;
        display: flex;
        margin-top: 30px;
        justify-content: center;
        align-items: center;

        li{
            width: 28px;
            height: 28px;
            margin: 4px;
list-style: none;
            img{
                width: 28px;
                height: 28px;
            }
        }
    }
}


@media (max-width: 1920px)  {
    .Home-card{
        width: 1240px;

    }
    .card{
        width: 380px;
    }
}

@media (max-width: 1520px)  {
    .Home-card{
        width: 1240px;

    }
    .card{
        width: 380px;
    }
}

@media (max-width: 1280px)  {
    .Home-card{
        width: 800px;

    }
    .card{
        width: 360px;
    }
}